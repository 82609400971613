import { getDefaultClient } from 'connectkit';
import { createClient, mainnet } from 'wagmi';
import { goerli, sepolia, hardhat } from 'wagmi/chains';

const chains = [];

switch (process.env.NEXT_PUBLIC_CHAIN) {
  case 'sepolia':
    chains.push(sepolia);
    break;
  case 'goerli':
    chains.push(goerli);
    break;
  case 'hardhat':
    chains.push(hardhat);
    break;
  default:
    chains.push(mainnet);
}

export const connectKitClient = createClient(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getDefaultClient({
    appName: 'HeeDong',
    chains,
  })
);
