/* eslint-disable react/jsx-no-constructed-context-values */
import type { SendTransactionResult } from '@wagmi/core';
import type React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';

type TransactionType = {
  tx?: SendTransactionResult;
  calling: boolean; // calling represents when metamask pops up but before click
};

type ContextTypes = {
  error: Error;
  setError: Dispatch<SetStateAction<Error>>;
  pendingTx: TransactionType;
  setPendingTx: Dispatch<SetStateAction<TransactionType>>;
};

const AppContext = createContext<ContextTypes>({} as ContextTypes);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  // Define the state and methods for your WaitlistProvider here
  const [error, setError] = useState<Error>(new Error());
  const [pendingTx, setPendingTx] = useState<TransactionType>({
    calling: false,
  });
  return (
    <AppContext.Provider
      value={{
        setError,
        error,
        pendingTx,
        setPendingTx,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
};
