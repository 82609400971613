/** @type {import('next-seo').DefaultSeoProps} */
const defaultSEOConfig = {
  title: 'HeeDong Meets',
  titleTemplate: '%s | Welcome to the Wonderful World of HeeDong',
  defaultTitle: 'HeeDong',
  description:
    'HeeDong is a larger-than-life Boston-Terrier character created by artist and illustrator Aaron Chang. He is inspired by Aaron’s real-life dog and his wonderment about how HeeDong would view his life. HeeDong is your best friend and companion wherever you go, embodying universal appeal across all ages while maintaining a relatable persona that is both adorable and endearing.',
  openGraph: {
    title: 'Welcome to the Wonderful World of HeeDong',
    description:
      'HeeDong is a larger-than-life Boston-Terrier character created by artist and illustrator Aaron Chang. He is inspired by Aaron’s real-life dog and his wonderment about how HeeDong would view his life. HeeDong is your best friend and companion wherever you go, embodying universal appeal across all ages while maintaining a relatable persona that is both adorable and endearing.',
    images: [
      {
        url: 'https://adopt.heedong.io/heedong-og.jpg',
        alt: 'HeeDong',
      },
    ],
    site_name: 'HeeDong',
  },
  twitter: {
    handle: '@HeeDongNFT',
    cardType: 'summary_large_image',
  },
};

export default defaultSEOConfig;
